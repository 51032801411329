/*DropDown*/
.dropdown-menu li > a:hover, .dropdown-menu li > a:focus, .dropdown-submenu:hover > a {
    background-image: none;
    background-color: #ee5f5b;
}

/*Nav*/
.dropdown-menu li>a:hover, .dropdown-menu .active>a, .dropdown-menu .active>a:hover {
color: #fff;
text-decoration: none;
background-color: #ee5f5b;
}

.dropdown-menu a {
display: block;
padding: 3px 15px;
clear: both;
font-weight: normal;
line-height: 18px;
color: #333;
white-space: nowrap;
}
/*Datetime Picker*/
.bootstrap-datetimepicker-widget td.active:hover, .bootstrap-datetimepicker-widget td.active:hover:hover, .bootstrap-datetimepicker-widget td.active:active, .bootstrap-datetimepicker-widget td.active:hover:active, .bootstrap-datetimepicker-widget td.active.active, .bootstrap-datetimepicker-widget td.active:hover.active, .bootstrap-datetimepicker-widget td.active.disabled, .bootstrap-datetimepicker-widget td.active:hover.disabled, .bootstrap-datetimepicker-widget td.active[disabled], .bootstrap-datetimepicker-widget td.active:hover[disabled] {
color: #fff;
background-color: #bd362f;
}

.bootstrap-datetimepicker-widget td.active, .bootstrap-datetimepicker-widget td.active:hover {
color: #fff;
background-color: #ee5f5b;
background-image: -moz-linear-gradient(top,#ee5f5b,#bd362f);
background-image: -webkit-gradient(linear,0 0,0 100%,from(#ee5f5b),to(#bd362f));
background-image: -webkit-linear-gradient(top,#ee5f5b,#bd362f);
background-image: -o-linear-gradient(top,#ee5f5b,#bd362f);
background-image: linear-gradient(to bottom,#ee5f5b,#bd362f);
background-repeat: repeat-x;
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc',endColorstr='#ff0044cc',GradientType=0);
border-color: #bd362f #bd362f #802420;
border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
color: #fff;
text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}

[class^="icon-"]:last-child, [class*=" icon-"]:last-child {
}
[class^="icon-"], [class*=" icon-"] {
display: inline-block;
width: 14px;
height: 14px;
margin-top: 1px;
line-height: 14px;
vertical-align: text-top;
background-image: url("images/glyphicons-halflings.png");
background-repeat: no-repeat;

}

.bootstrap-datetimepicker-widget td span.active:hover, .bootstrap-datetimepicker-widget td span.active:active, .bootstrap-datetimepicker-widget td span.active.active, .bootstrap-datetimepicker-widget td span.active.disabled, .bootstrap-datetimepicker-widget td span.active[disabled] {
color: #fff;
background-color: #ee5f5b;
}

.bootstrap-datetimepicker-widget td span.active {
color: #fff;
background-color: #ee5f5b;
background-image: -moz-linear-gradient(top,#ee5f5b,#bd362f);
background-image: -webkit-gradient(linear,0 0,0 100%,from(#ee5f5b),to(#bd362f));
background-image: -webkit-linear-gradient(top,#ee5f5b,#bd362f);
background-image: -o-linear-gradient(top,#ee5f5b,#bd362f);
background-image: linear-gradient(to bottom,#ee5f5b,#bd362f);
background-repeat: repeat-x;
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc',endColorstr='#ff0044cc',GradientType=0);
border-color: #bd362f #bd362f #802420;
border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
color: #fff;
text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}

/*Nav*/
.nav-list>.active>a, .nav-list>.active>a:hover, .nav-list>.active>a:focus {
color: #fff;
text-shadow: 0 -1px 0 rgba(0,0,0,0.2);
background-color: #ee5f5b;
}

/*a*/
a {
color: #ee5f5b;
text-decoration: none;
}

a:hover, a:focus {
color: #bd362f;
text-decoration: underline;
}

/*Validation*/
.field-validation-error {
    color: #bd362f;
}

/*TextBody*/
textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
border-color: rgba(238,95,91,0.4);
outline: 0;
/*outline: thin dotted \9;*/
-webkit-box-shadow: inset 0 1px 1px rgba(238,95,91,0.4),0 0 8px rgba(238,95,91,0.4);
-moz-box-shadow: inset 0 1px 1px rgba(238,95,91,0.4),0 0 8px rgba(238,95,91,0.4);
box-shadow: inset 0 1px 1px rgba(238,95,91,0.4),0 0 8px rgba(238,95,91,0.4);
}

.table th{
padding: 8px;
line-height: 20px;

vertical-align: top;
border-top: 1px solid #ddd;
}
.table td {
  padding: 8px;
line-height: 20px;

vertical-align: top;
border-top: 1px solid #ddd;
}