/* app css stylesheet */

.menu {
  list-style: none;
  border-bottom: 0.1em solid black;
  margin-bottom: 2em;
  padding: 0 0 0.5em;
}

.menu:before {
  content: "[";
}

.menu:after {
  content: "]";
}

.menu > li {
  display: inline;
}

.menu > li:before {
  content: "|";
  padding-right: 0.3em;
}

.menu > li:nth-child(1):before {
  content: "";
  padding: 0;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none;
}

.blue{
    color:blue;
}