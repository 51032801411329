/* You can add global styles to this file, and also import other style files */


    body {
      padding-top: 60px;
      padding-bottom: 40px;
    } 

    .table .table-striped > tr > td {
        min-height: 57px;
    }

    .rd-select {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}


.rd-select {
    width: 220px;
    background-color: #fff;
    border: 1px solid #ccc;
}

.rd-select {
    height: 30px;
    line-height: 30px;
}

.rd-select {
    display: inline-block;
    height: 20px;
    padding: 4px 6px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #555;
    vertical-align: middle;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.rd-select {
    height: 30px;
    line-height: 30px;
        -webkit-appearance: menulist;
    box-sizing: border-box;
    align-items: center;
    white-space: inherit;
    -webkit-rtl-ordering: logical;
    
}
.rd-select:not(ul) {
    overflow: visible !important;
}
/*select:not(:-internal-list-box) {
    overflow: visible !important;
}*/